import React from 'react'

import { Button, Col, Row } from 'reactstrap'

import './Hero.css'

function Hero({ seeMinerals, seeLeasehold }) {
  return (
    <Row className="hero">
      <Col md={12} lg={6} className="first-column">
        <div className="hero-text-container">
          <h1>
            Connecting buyers and sellers of cash flowing minerals and leasehold
          </h1>
          <p>
            Mineral Market is a new company dedicated to simplifying the market
            for cash flowing minerals. Our goal is to bring together Buyers and
            Sellers of cash flowing minerals through an easy-to-use interface
            that reduces transaction costs.
          </p>
        </div>
        <div className="button-container">
          <Button className="default-button see-minerals" onClick={seeMinerals}>
            SEE MINERALS
          </Button>
          <Button
            className="default-button see-leasehold"
            onClick={seeLeasehold}
          >
            SEE LEASEHOLD
          </Button>
        </div>
      </Col>
      <Col md={12} lg={6} className="second-column">
        <div className="circle circle-badge first">
          <span className="arrow">&#8679;</span>10%
        </div>
        <div className="circle circle-badge second">
          <span className="arrow">&#8679;</span>12%
        </div>
        <div className="circle circle-badge third">
          <span className="arrow">&#8679;</span>12%
        </div>
        <div className="circle circle-badge fourth">
          <span className="arrow">&#8679;</span>18%
        </div>
        <div className="circle circle-badge fifth">
          <span className="arrow">&#8679;</span>25%
        </div>
        <div className="circle circle-badge sixth">
          <span className="arrow">&#8679;</span>30%
        </div>
        <div className="absolute-position first">
          <div className="vl" />
        </div>
        <div className="absolute-position second">
          <div className="vl" />
        </div>
        <div className="absolute-position third">
          <div className="vl" />
        </div>
        <div className="absolute-position fourth">
          <div className="vl" />
        </div>
        <div className="absolute-position fifth">
          <div className="vl" />
        </div>
        <div className="absolute-position sixth">
          <div className="vl" />
        </div>
      </Col>
    </Row>
  )
}
export default Hero
