import React from 'react'

import { Card, CardBody, Col, Container, Row } from 'reactstrap'

import './Legal.css'

const DEFAULT_DOCUMENT =
  'https://s3.amazonaws.com/defaultworkbucket/LOI-Mineral-Market.pdf'

function convertToObj(documents) {
  let obj = {}
  documents.forEach(document => {
    if (!obj[document.name]) {
      obj[document.name] = document.document
    }
  })
  return obj
}
class Legal extends React.Component {
  state = {
    documents: {}
  }
  async componentDidMount() {
    let documents = await fetch('https://mineralmarket.herokuapp.com/documents')
    try {
      documents = await documents.json()
    } catch (err) {
      console.log(err)
    }
    const convertDocumentsToObj = convertToObj(documents)
    this.setState({ documents: convertDocumentsToObj })
  }
  render() {
    return (
      <Container fluid className="legal">
        <Row>
          <Col className="heading">
            <h3>Legal</h3>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col className="img-col" sm={12} md={6}>
            <div className="legal-text">
              <p>
                Buyers acknowledge that Mineral Market has entered into a
                Seller’s Agreement with each Seller whose minerals or leasehold
                are offered on the app granting Mineral Market the exclusive
                right to sell their minerals or leasehold for a period of time.
              </p>
              <p>
                Buyers agree not to circumvent or interfere with Mineral
                Market’s rights under the Seller’s Agreement.
              </p>
            </div>
            <img src="/assets/scale.svg" alt="scale" />
          </Col>
          <Col className="text-col" sm={12} md={6}>
            <a
              href={this.state.documents['PSA Agreement'] || DEFAULT_DOCUMENT}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="download-link margin-bottom-link"
            >
              <Card>
                <CardBody>
                  <p>DOWNLOAD PSA AGREEMENT</p>
                  <div className="circle">
                    <span>&#8594;</span>
                  </div>
                </CardBody>
              </Card>
            </a>
            <a
              href={this.state.documents['Mineral Deed'] || DEFAULT_DOCUMENT}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="download-link margin-bottom-link"
            >
              <Card>
                <CardBody>
                  <p>DOWNLOAD MINERAL DEED</p>
                  <div className="circle">
                    <span>&#8594;</span>
                  </div>
                </CardBody>
              </Card>
            </a>
            <a
              href={
                this.state.documents['Assignment of O&G Lease'] ||
                DEFAULT_DOCUMENT
              }
              download
              target="_blank"
              rel="noopener noreferrer"
              className="download-link margin-bottom-link"
            >
              <Card>
                <CardBody>
                  <p>DOWNLOAD ASSIGNMENT OF O&G LEASE</p>
                  <div className="circle">
                    <span>&#8594;</span>
                  </div>
                </CardBody>
              </Card>
            </a>
            <a
              href={
                this.state.documents['Buyers Agreement'] || DEFAULT_DOCUMENT
              }
              download
              target="_blank"
              rel="noopener noreferrer"
              className="download-link margin-bottom-link"
            >
              <Card>
                <CardBody>
                  <p>DOWNLOAD BUYER'S AGREEMENT</p>
                  <div className="circle">
                    <span>&#8594;</span>
                  </div>
                </CardBody>
              </Card>
            </a>
            <a
              href={
                this.state.documents['Sellers Agreement'] || DEFAULT_DOCUMENT
              }
              download
              target="_blank"
              rel="noopener noreferrer"
              className="download-link"
            >
              <Card>
                <CardBody>
                  <p>DOWNLOAD SELLER'S AGREEMENT</p>
                  <div className="circle">
                    <span>&#8594;</span>
                  </div>
                </CardBody>
              </Card>
            </a>
          </Col>
        </Row>
      </Container>
    )
  }
}
export default Legal
