import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import MineralLeasehold from './MineralLeasehold'
import { reduceAssetData, reorganiseAssetData } from './utils'

class MineralLeaseholdContainer extends Component {
  state = {
    assets: []
  }
  goToAsset = (asset, type) => {
    this.props.history.push({
      pathname: `/asset/${asset.id}`,
      state: { asset, type }
    })
  }
  async componentDidMount() {
    const assets = await reduceAssetData(this)
    this.setState({ assets })
  }
  async componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const assets = await reduceAssetData(this)
      this.setState({ assets })
    }
  }
  render() {
    const [assets, counties] = reorganiseAssetData(this.state.assets)
    return (
      <>
        {this.state.assets.length > 0 ? (
          <MineralLeasehold
            title={this.props.title}
            onScrollClick={this.props.onScrollClick}
            goToAsset={this.goToAsset}
            assets={assets}
            counties={counties}
            user={this.props.user}
            loading={false}
            checkForUser={this.props.checkForUser}
          />
        ) : (
          <MineralLeasehold
            title={this.props.title}
            onScrollClick={this.props.onScrollClick}
            user={this.props.user}
            loading={true}
            checkForUser={this.props.checkForUser}
          />
        )}
      </>
    )
  }
}

export default withRouter(MineralLeaseholdContainer)
