import React from 'react'
import { Card, CardBody } from 'reactstrap'

export function cardWithText(text, classes) {
  return (
    <Card>
      <CardBody>
        <p className={classes}>{text}</p>
      </CardBody>
    </Card>
  )
}

/**
 * Returns a number: ie 10434 with commas -> 10,434
 */
export function numberWithCommas(x) {
  if (!x) return null
  const decimalPlace = x.toString().indexOf('.')
  let decimalValue
  if (decimalPlace > -1) {
    decimalValue = x.toString().slice(decimalPlace, x.length)
    x = x.toString().slice(0, decimalPlace)
  } else {
    decimalValue = '.00'
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + decimalValue
}

/**
 * Returns a number padded with zeroes if needed (3 decimal places)
 * 1.2 -> 1.200
 * 1.22 -> 1.220
 * 1.24325 -> 1.243
 */
export function addZeroesIfNeeded(x) {
  if (!x) return null
  const xAsString = x.toString()
  const locationOfPeriod = xAsString.indexOf('.')
  if (locationOfPeriod === -1) {
    x = x.toString() + '.000'
    return x
  }
  const numbersAfterPeriod = xAsString.slice(
    locationOfPeriod + 1,
    xAsString.length
  ).length
  switch (numbersAfterPeriod) {
    case 1:
      return xAsString + '00'
    case 2:
      return xAsString + '0'
    default:
      if (numbersAfterPeriod > 3) {
        return xAsString.slice(0, xAsString.length - (numbersAfterPeriod - 3))
      }
      return xAsString
  }
}
