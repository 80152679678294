import React from 'react'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'

import NavbarContainer from '../../Components/Navbar/NavbarContainer'
import Footer from '../../Components/Footer/Footer'
import { addZeroesIfNeeded, numberWithCommas, cardWithText } from './utils'

import './Asset.css'

function Asset({ asset, user, checkForUser, type }) {
  const {
    legalDesc,
    map,
    county,
    pricePerAcre,
    operator,
    wellName,
    numberOfMonths,
    averageRevenue,
    total,
    other
  } = asset
  let { royaltyRate, nri, acres, averageLOE } = asset
  // the word yield is a keyword, cannot be set to variable name
  let yeild = asset['yield']

  acres = addZeroesIfNeeded(acres)
  if (yeild) {
    yeild = addZeroesIfNeeded(yeild)
  } else {
    averageLOE = numberWithCommas(averageLOE)
  }

  if (royaltyRate) {
    royaltyRate = addZeroesIfNeeded(royaltyRate)
  } else {
    royaltyRate = addZeroesIfNeeded(nri)
  }
  const pricePerAcreWithCommas = numberWithCommas(pricePerAcre)
  return (
    <>
      <div className="asset">
        <NavbarContainer
          version="asset"
          user={user}
          checkForUser={checkForUser}
        />
        {user.name ? (
          <>
            <div className="heading">
              <Card>
                <CardBody>
                  <p>{legalDesc}</p>
                </CardBody>
              </Card>
            </div>
            <div className="location">
              <h5>LOCATION</h5>
              <img src={map} alt="assets location" />
            </div>
            <Container>
              <h6>INFORMATION</h6>
              <Row>
                <Col xs={6} md={3}>
                  {cardWithText('COUNTY', 'info-title')}
                  {cardWithText('LEGAL NAME', 'info-title')}
                  {cardWithText('ACRES', 'info-title')}
                  {type === 'Minerals' &&
                    cardWithText('ROYALTY (%)', 'info-title')}
                  {type === 'Leasehold' &&
                    cardWithText('N.R.I. (%)', 'info-title')}
                  {cardWithText('PRICE PER ACRE ($)', 'info-title')}
                </Col>
                <Col xs={6} md={3}>
                  {cardWithText(county, 'green info-data')}
                  {cardWithText(legalDesc, 'green info-data')}
                  {cardWithText(acres, 'green info-data')}
                  {cardWithText(`${royaltyRate}%`, 'green info-data')}
                  {cardWithText(
                    `$${pricePerAcreWithCommas}`,
                    'green info-data'
                  )}
                </Col>
                <Col xs={6} md={3}>
                  {cardWithText('OPERATOR', 'info-title')}
                  {cardWithText('WELL NAME', 'info-title')}
                  {cardWithText('NUMBER OF MONTHS', 'info-title')}
                  {cardWithText('AVERAGE REVENUE', 'info-title')}
                  {type === 'Minerals' &&
                    cardWithText('YIELD (%)', 'info-title')}
                  {type === 'Leasehold' &&
                    cardWithText('AVERAGE LOE', 'info-title')}
                  {other && cardWithText('OTHER INFO', 'info-title')}
                </Col>
                <Col xs={6} md={3}>
                  {cardWithText(operator, 'green info-data')}
                  {cardWithText(wellName, 'green info-data')}
                  {cardWithText(numberOfMonths, 'green info-data')}
                  {cardWithText(averageRevenue, 'green info-data')}
                  {yeild && cardWithText(`${yeild}%`, 'green info-data')}
                  {averageLOE &&
                    cardWithText(`${averageLOE}`, 'green info-data')}
                  {other && cardWithText(other, 'green info-data')}
                </Col>
              </Row>
              <Row className="total-row">
                <Col>{cardWithText('TOTAL', 'bold info-title')}</Col>
                <Col>{cardWithText(total, 'green bold info-data')}</Col>
              </Row>
              <Row>
                <Button
                  className="default-button"
                  href={`mailto:all@cherokeeacq.com?Subject=Mineral%20Market%20Inquiry%20about%20${legalDesc}`}
                >
                  INQUIRE
                </Button>
              </Row>
            </Container>
            <Footer version="mineralLeasehold" />
          </>
        ) : (
          <>
            <div className="alert-container">
              <div className="alert alert-info" role="alert">
                Please Login to view individual assets.
              </div>
            </div>
            <Footer version="mineralLeasehold" absolute={true} />
          </>
        )}
      </div>
    </>
  )
}

export default Asset
