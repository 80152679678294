import React, { Component } from 'react'

import Asset from './Asset'
import FourOFour from '../404/404'

class AssetContainer extends Component {
  state = {
    asset: {}
  }
  sendInquiry = () => {}

  render() {
    const {
      location: {
        state: { asset, type }
      },
      user,
      checkForUser
    } = this.props

    return (
      <>
        {asset ? (
          <Asset
            asset={asset}
            type={type}
            user={user}
            checkForUser={checkForUser}
          />
        ) : (
          <FourOFour checkForUser={checkForUser} user={user} />
        )}
      </>
    )
  }
}

export default AssetContainer
