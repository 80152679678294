import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import scrollToComponent from 'react-scroll-to-component'

import createBrowserHistory from 'history/createBrowserHistory'

import Routes from './Routes/Routes'
import CheckUserSession from './ApiCalls/CheckUserSession'

const history = createBrowserHistory()

class App extends React.Component {
  constructor(props) {
    super(props)
    this.home = {}
    // refs are the recommended way to add scrollTo logic
    // to other parts of a page in react
    // https://reactjs.org/docs/refs-and-the-dom.html
    this.howItWorks = React.createRef()
    this.howToPurchase = React.createRef()
    this.howToSell = React.createRef()
    this.legal = React.createRef()
    this.contactUs = React.createRef()
    this.state = {
      user: {}
    }
  }
  onScrollClick = section => {
    scrollToComponent(this[section].current)
  }
  componentDidMount() {
    if (!this.state.user.name) {
      this.checkForUser()
    }
  }
  checkForUser = async loggedOut => {
    if (!this.state.user.name || loggedOut === true) {
      let checkIfThereIsUser = await CheckUserSession()
      if (checkIfThereIsUser && checkIfThereIsUser.success) {
        this.setState({ user: checkIfThereIsUser.user })
      } else {
        this.setState({ user: {} })
      }
    }
  }
  shouldComponentUpdate(_, nextState) {
    if (this.state.user.email !== nextState.user.email) {
      return true
    }
    return false
  }

  render() {
    return (
      <Router>
        <Routes
          history={history}
          home={this.home}
          howItWorks={this.howItWorks}
          howToPurchase={this.howToPurchase}
          howToSell={this.howToSell}
          legal={this.legal}
          contactUs={this.contactUs}
          onScrollClick={this.onScrollClick}
          user={this.state.user}
          checkForUser={this.checkForUser}
        />
      </Router>
    )
  }
}
export default App
