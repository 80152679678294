export async function reduceAssetData(self) {
  let response
  const pathname = self.props.location.pathname
  if (pathname === '/leaseholds' || pathname === '/Leaseholds') {
    response = await fetch('https://mineralmarket.herokuapp.com/leaseholds')
  } else {
    response = await fetch('https://mineralmarket.herokuapp.com/assets')
  }
  let assets
  try {
    assets = await response.json()
  } catch (err) {
    console.log(err)
  }
  // get rid of corrupted assets
  assets = assets.filter(asset => {
    if (asset.id === '5c53460c6f08087a8131e74e') {
      return false
    } else if (asset.id === '5c53461c6f08087a8131e74f') {
      return false
    } else {
      return true
    }
  })
  assets.reduce((asset, organizedAssetData) => {
    if (!organizedAssetData[asset.county]) {
      organizedAssetData[asset.county] = []
      organizedAssetData[asset.county].push(asset)
    } else {
      organizedAssetData[asset.county].push(asset)
    }
    return organizedAssetData
  }, {})
  return assets
}

function alterAssetAverageRevenue(averageRevenue) {
  const averageRevenueString = averageRevenue.toFixed(2)
  const numberWithCommas = averageRevenueString.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  )
  return `$${numberWithCommas} PER MONTH`
}

function alterTotal(total) {
  const totalString = total.toFixed(2)
  const numberWithCommas = totalString.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return `$${numberWithCommas}`
}

export function reorganiseAssetData(assetData) {
  let hashedByCounty = {}
  for (const asset of assetData) {
    // deep clone the asset
    const alteredAsset = JSON.parse(JSON.stringify(asset))
    // first alter names in the asset data and how it looks
    // for the future presentational asset component
    alteredAsset.averageRevenue = alterAssetAverageRevenue(asset.averageRev)
    alteredAsset.county = asset.county.toUpperCase()
    alteredAsset.total = alterTotal(asset.total)
    // yield is a reserved word
    alteredAsset['yield'] = asset.yield
    const county = alteredAsset.county
    if (!hashedByCounty[county]) {
      hashedByCounty[county] = []
      hashedByCounty[county].push(alteredAsset)
    } else {
      hashedByCounty[county].push(alteredAsset)
    }
  }
  const counties = Object.keys(hashedByCounty)
  let arrayForRendering = counties.reduce((accumulator, county) => {
    accumulator.push(hashedByCounty[county])
    return accumulator
  }, [])
  return [arrayForRendering, counties]
}
