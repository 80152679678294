export default contactUsData => {
  contactUsData.name = contactUsData.firstName + ' ' + contactUsData.lastName
  return fetch('/api/email', {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(contactUsData)
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      console.log(err)
      return err
    })
}
