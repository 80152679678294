import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import SendEmail from '../../ApiCalls/SendEmail'

import Home from './Home'

class HomeContainer extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    subject: '',
    message: '',
    finalMessage: ''
  }
  saveToState = e => {
    const {
      target: { name, value }
    } = e
    this.setState({ [name]: value })
  }
  seeMinerals = () => this.props.history.push('/assets')
  seeLeasehold = () => this.props.history.push('/leaseholds')
  onContactUsSubmit = async ev => {
    ev.preventDefault()
    const emailResponse = await SendEmail(this.state)
    if (emailResponse.success) {
      this.setState({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        subject: '',
        message: '',
        finalMessage: 'Thanks for contacting us!'
      })
    } else {
      this.setState({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        subject: '',
        message: '',
        finalMessage:
          'Something went wrong... please try again in a few minutes!'
      })
    }
    const feedback = document.querySelector('.valid-feedback')
    feedback.style.display = 'block'
  }
  validateEmail(e) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const { validate } = this.state
    if (emailRex.test(e.target.value)) {
      validate.emailState = 'has-success'
    } else {
      validate.emailState = 'has-danger'
    }
    this.setState({ validate })
  }
  componentDidMount() {
    this.props.checkForUser()
    if (this.props.location && this.props.location.state) {
      this.props.onScrollClick(this.props.location.state.section)
    }
  }
  render() {
    return (
      <Home
        history={this.props.history}
        seeMinerals={this.seeMinerals}
        seeLeasehold={this.seeLeasehold}
        downloadApp={this.downloadApp}
        downloadMineralDeedDocument={this.downloadMineralDeedDocument}
        downloadPSAAgreement={this.downloadPSAAgreement}
        onContactUsSubmit={this.onContactUsSubmit}
        saveToState={this.saveToState}
        contactUsFormState={this.state}
        onScrollClick={this.props.onScrollClick}
        home={this.props.home}
        howItWorks={this.props.howItWorks}
        howToPurchase={this.props.howToPurchase}
        howToSell={this.props.howToSell}
        legal={this.props.legal}
        contactUs={this.props.contactUs}
        user={this.props.user}
        checkForUser={this.props.checkForUser}
      />
    )
  }
}

export default withRouter(HomeContainer)
