import React from 'react'

import { Input, InputGroup, InputGroupAddon } from 'reactstrap'

function Phone({ required, saveToState, value }) {
  return (
    <InputGroup>
      <InputGroupAddon addonType="prepend">
        <img src="/assets/flag-minmarket.svg" alt="small flag" />
      </InputGroupAddon>
      <Input
        placeholder="Phone"
        type="tel"
        name="mobileNumber"
        autoComplete="tel"
        required={required}
        onChange={saveToState}
        value={value}
      />
    </InputGroup>
  )
}

export default Phone
