import React from 'react'

import { Input, InputGroup, InputGroupAddon } from 'reactstrap'

function Email({ required, saveToState, value }) {
  return (
    <InputGroup>
      <InputGroupAddon addonType="prepend">
        <img src="/assets/flag-minmarket.svg" alt="small flag" />
      </InputGroupAddon>
      <Input
        placeholder="Email"
        type="email"
        name="email"
        autoComplete="email"
        required={required}
        onChange={saveToState}
        value={value}
      />
    </InputGroup>
  )
}

export default Email
