import React from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'

import NavbarContainer from '../../Components/Navbar/NavbarContainer'
import Footer from '../../Components/Footer/Footer'

import './MineralLeasehold.css'

/**
 *
 * @param {Array} assets
 * @param {Function} goToAsset
 * @param {String} type
 * @param {Array} counties
 * @returns {JSX}
 *
 * @summary You are given an array of assets. Each index contains a category array.
 *          Each category array contains one or many assets related to it.
 *          The first reduction turns all categories into columns of JSX
 *          The second step is to create additional rows in case there are more than
 *          three categories
 *
 */
function createCategories(assets, goToAsset, type, counties) {
  const categories = assets.reduce((accum, asset, i) => {
    const assetJSX = createCategory(asset, goToAsset, type, counties, i)
    accum.push(assetJSX)
    return accum
  }, [])
  let finalJSX = [],
    categoriesArray = []
  if (categories.length > 3) {
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i]
      categoriesArray.push(category)
      if (categoriesArray.length === 3) {
        finalJSX.push(<Row key={i}>{categoriesArray}</Row>)
        categoriesArray = []
      }
    }
    if (categoriesArray.length)
      finalJSX.push(<Row key={23}>{categoriesArray}</Row>)
    return finalJSX
  } else {
    return <Row>{categories}</Row>
  }
}
/**
 *
 * @param {Object} asset
 * @param {Function} goToAsset
 * @param {String} type
 * @param {Array} counties
 * @param {Number} i
 * @returns {JSX}
 *
 * @summary This creates the column and title for each category,
 *          whilst putting all cards as children to this
 */
function createCategory(asset, goToAsset, type, counties, i) {
  return (
    <Col md={4} key={i}>
      <h5>
        <span>{counties[i]}</span>
      </h5>
      {createCategoryCards(goToAsset, asset, type)}
    </Col>
  )
}
/**
 *
 * @param {Function} goToAsset
 * @param {String} type
 * @param {String} Category
 * @returns {JSX}
 *
 * @summary This creates all cards for all assets related to a category
 */
function createCategoryCards(goToAsset, category, type) {
  return category.reduce((categoryCards, asset) => {
    const card = createCard(goToAsset, asset, type)
    categoryCards.push(card)
    return categoryCards
  }, [])
}
/**
 *
 * @param {Function} goToAsset
 * @param {String} type
 * @param {String} Category
 * @returns {JSX}
 *
 * @summary This creates the individual cards for the assets
 *          (to be clicked leading to individual asset page)
 */
function createCard(goToAsset, asset, type) {
  return (
    <Card key={asset.id}>
      <CardBody>
        <p>{asset.legalDesc}</p>
        <button onClick={() => goToAsset(asset, type)} className="circle">
          <span>&#8594;</span>
        </button>
      </CardBody>
    </Card>
  )
}

function MineralLeasehold({
  goToAsset,
  title,
  assets,
  counties,
  user,
  history,
  onScrollClick,
  loading,
  checkForUser
}) {
  return (
    <div className="mineral-leasehold">
      <NavbarContainer
        version="mineralsLeasehold"
        user={user}
        history={history}
        onScrollClick={onScrollClick}
        checkForUser={checkForUser}
      />
      <div className="heading">
        <h2>{title}</h2>
      </div>
      <hr />
      {loading !== true ? (
        <Container className="asset-listing">
          {createCategories(assets, goToAsset, title, counties)}
        </Container>
      ) : (
        <div className="loading-indicator">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="alert" role="alert">
            Asset data is loading...
          </div>
        </div>
      )}
      <Footer version="mineralLeasehold" />
    </div>
  )
}

export default MineralLeasehold
