import React from 'react'

import {
  Button,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap'

import './Navbar.css'
import SignIn from '../../Pages/SignIn/SignIn'
import SignUp from '../../Pages/SignUp/SignUp'

function NavbarComponent({
  signInOpen,
  signUpOpen,
  modalToggle,
  toggle,
  state,
  onScrollClick,
  linkToHome,
  version,
  user,
  redirectToHome,
  logout,
  checkForUser
}) {
  return (
    <Navbar className="main-navbar" expand="lg">
      <NavbarBrand onClick={linkToHome}>MINERAL MARKET</NavbarBrand>
      <NavbarToggler onClick={toggle} className="navbar-light" />
      <Collapse isOpen={state.isOpen} navbar>
        <Nav navbar>
          {(version === 'home' && (
            <>
              <NavItem>
                <NavLink onClick={_ => onScrollClick('howItWorks')}>
                  How it Works
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={_ => onScrollClick('howToPurchase')}>
                  How to Buy
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={_ => onScrollClick('howToSell')}>
                  How to Sell
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={_ => onScrollClick('legal')}>Legal</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={_ => onScrollClick('contactUs')}>
                  Contact Us
                </NavLink>
              </NavItem>
              {user && user.name && (
                <div className="user-container hide-when-large">
                  <p>
                    Welcome, <span className="user-name">{user.name}</span>
                  </p>
                  <button onClick={logout} className="button-link">
                    <span className="badge badge-light">Logout</span>
                  </button>
                </div>
              )}
            </>
          )) || (
            <>
              <NavItem>
                <NavLink
                  onClick={_ => {
                    redirectToHome('howItWorks')
                  }}
                >
                  How it Works
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={_ => {
                    redirectToHome('howToPurchase')
                  }}
                >
                  How to Purchase
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={_ => {
                    redirectToHome()
                    onScrollClick('howToSell')
                  }}
                >
                  How to Sell
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={_ => {
                    redirectToHome()
                    onScrollClick('legal')
                  }}
                >
                  Legal
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={_ => {
                    redirectToHome()
                    onScrollClick('contactUs')
                  }}
                >
                  Contact Us
                </NavLink>
              </NavItem>
              {user && user.name && (
                <div className="user-container hide-when-large">
                  <p>
                    Welcome, <span className="user-name">{user.name}</span>
                  </p>
                  <button onClick={logout} className="button-link">
                    <span className="badge badge-light">Logout</span>
                  </button>
                </div>
              )}
            </>
          )}
        </Nav>
        {user && user.name ? (
          <div className="user-container hide-when-small">
            <p>
              Welcome, <span className="user-name">{user.name}</span>
            </p>
            <button onClick={logout} className="button-link">
              <span className="badge badge-light">Logout</span>
            </button>
          </div>
        ) : (
          <div className="button-container">
            <>
              <Button
                className="default-button"
                onClick={_ => modalToggle('signInOpen')}
              >
                LOG IN
              </Button>
              <SignIn
                signInOpen={signInOpen}
                modalToggle={modalToggle}
                checkForUser={checkForUser}
              />
            </>
            <>
              <Button
                className="default-button"
                onClick={_ => modalToggle('signUpOpen')}
              >
                SIGN UP
              </Button>
              <SignUp
                signUpOpen={signUpOpen}
                modalToggle={modalToggle}
                checkForUser={checkForUser}
              />
            </>
          </div>
        )}
      </Collapse>
    </Navbar>
  )
}

export default NavbarComponent
