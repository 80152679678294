import React from 'react'

function Radio({ label, name, saveToState, value }) {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        name={name}
        type="radio"
        checked={value}
        onChange={saveToState}
        id={name}
      />
      <label className="form-check-label" htmlFor={name}>
        {label}
      </label>
    </div>
  )
}

export default Radio
