import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { Alert, Button, Modal, ModalHeader, ModalBody, Form } from 'reactstrap'

import Text from '../../Components/Form/Inputs/Text'
import Email from '../../Components/Form/Inputs/Email'
import loginUser from '../../ApiCalls/Login'

import './SignIn.css'

class SignIn extends Component {
  state = {
    name: '',
    email: ''
  }
  saveToState = e => {
    const {
      target: { name, value }
    } = e
    this.setState({ [name]: value })
  }
  onSubmit = async e => {
    e.preventDefault()
    const name = this.state.name
    const email = this.state.email
    const loggedInUser = await loginUser({ name, email })
    if (loggedInUser.success) {
      this.props.checkForUser()
      this.props.modalToggle('signInOpen')
    } else {
      const alert = document.querySelector('.alert')
      alert.innerHTML = 'The email or name does not exist.'
      alert.style.display = 'block'
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.signInOpen !== prevProps.signInOpen) {
      this.setState({ modal: this.props.signInOpen })
      const root = document.querySelector('#root')
      const nameInput = document.getElementsByName('name')[0]
      nameInput.focus()
      // close modal with escape
      root.addEventListener('keyup', e => {
        if (e.key === 'Escape') {
          if (this.props.signInOpen === true) {
            this.props.modalToggle('signInOpen')
          }
        }
      })
    }
  }
  render() {
    return (
      <Modal
        className="sign-in"
        isOpen={this.props.signInOpen}
        toggle={_ => this.props.modalToggle('signInOpen')}
      >
        <Button
          aria-label="Close"
          className="close"
          onClick={_ => this.props.modalToggle('signInOpen')}
        >
          <span aria-hidden="true">&times;</span>
        </Button>
        <ModalHeader>SIGN IN</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.onSubmit}>
            <Text
              autoFocus
              autoComplete="name"
              name="name"
              placeholder="Name"
              required={true}
              saveToState={this.saveToState}
              value={this.state.name}
            />
            <Email
              required={true}
              saveToState={this.saveToState}
              value={this.state.email}
            />
            <Button className="default-button" type="submit">
              CONTINUE
            </Button>
            <Alert color="danger" />
          </Form>
        </ModalBody>
      </Modal>
    )
  }
}

export default withRouter(SignIn)
