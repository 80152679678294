import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { Alert, Button, Modal, ModalHeader, ModalBody, Form } from 'reactstrap'

import Text from '../../Components/Form/Inputs/Text'
import Phone from '../../Components/Form/Inputs/Phone'
import Email from '../../Components/Form/Inputs/Email'
import CheckBox from '../../Components/Form/Inputs/Radio'
import signupUser from '../../ApiCalls/Signup'
import loginUser from '../../ApiCalls/Login'

import './SignUp.css'

class SignUp extends Component {
  state = {
    name: '',
    company: '',
    mobileNumber: '',
    email: '',
    buyer: false,
    seller: false
  }
  saveToState = e => {
    const {
      target: { name, value, type }
    } = e
    if (type === 'radio') {
      if (name === 'buyer') {
        this.setState({ buyer: true, seller: false })
      } else {
        this.setState({ buyer: false, seller: true })
      }
      return
    }
    this.setState({ [name]: value })
  }
  onSubmit = async e => {
    const userObj = {
      username: this.state.email,
      fullname: this.state.name,
      created: new Date(),
      isSeller: this.state.seller
    }
    e.preventDefault()
    const returnStatus = await signupUser(userObj, this.state)
    if (returnStatus.success) {
      const username = returnStatus.username
      const id = returnStatus.id
      const user = returnStatus.user
      const email = user.email
      const name = user.name
      const loggedInUser = await loginUser({ name, email, username, id })
      if (loggedInUser.success) {
        this.props.checkForUser()
        this.props.modalToggle('signUpOpen')
      }
    } else {
      const alert = document.querySelector('.alert')
      if (
        returnStatus.stack.indexOf(
          'duplicate key error' || returnStatus.err === 'User already exists'
        )
      ) {
        alert.innerHTML = 'The email you inputted is already in use.'
      } else {
        alert.innerHTML = returnStatus.message
      }
      alert.style.display = 'block'
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.signUpOpen !== prevProps.signUpOpen) {
      this.setState({ modal: this.props.signUpOpen })
      const root = document.querySelector('#root')
      const nameInput = document.getElementsByName('name')[0]
      nameInput.focus()
      // close modal with escape
      root.addEventListener('keyup', e => {
        if (e.key === 'Escape') {
          if (this.props.signUpOpen === true) {
            this.props.modalToggle('signUpOpen')
          }
        }
      })
    }
  }
  render() {
    return (
      <Modal
        className="sign-up"
        isOpen={this.props.signUpOpen}
        toggle={_ => this.props.modalToggle('signUpOpen')}
      >
        <Button
          aria-label="Close"
          className="close"
          onClick={_ => this.props.modalToggle('signUpOpen')}
        >
          <span aria-hidden="true">&times;</span>
        </Button>
        <ModalHeader>SIGN UP</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.onSubmit}>
            <Text
              autoFocus={true}
              autoComplete="name"
              name="name"
              placeholder="Name"
              required={true}
              saveToState={this.saveToState}
              value={this.state.name}
            />
            <Text
              autoFocus={false}
              autoComplete="organization"
              name="company"
              placeholder="Company"
              required={true}
              saveToState={this.saveToState}
              value={this.state.company}
            />
            <Phone
              required={true}
              saveToState={this.saveToState}
              value={this.state.mobileNumber}
            />
            <Email
              required={true}
              saveToState={this.saveToState}
              value={this.state.email}
            />
            <div className="checkbox-container">
              <CheckBox
                label="BUYER"
                name="buyer"
                saveToState={this.saveToState}
                value={this.state.buyer}
              />
              <CheckBox
                label="SELLER"
                name="seller"
                saveToState={this.saveToState}
                value={this.state.seller}
              />
            </div>
            <Button className="default-button" type="submit">
              CREATE ACCOUNT
            </Button>
            <Alert color="danger" />
          </Form>
        </ModalBody>
      </Modal>
    )
  }
}

export default withRouter(SignUp)
