import React from 'react'

import { Col, Container, Row } from 'reactstrap'

import './Footer.css'

function Footer({ onScrollClick, version, absolute }) {
  return (
    <Container
      fluid
      className="footer"
    >
      <Row>
        <Col className="footer-col" md={version === 'home' ? 3 : 6}>
          <p className="schedule-call">Schedule a conversation</p>
          <a
            className="mail-to"
            href="mailto:all@cherokeeacq.com?Subject=Mineral%20Market%20Inquiry"
            target="_top"
          >
            all@cherokeeacq.com
          </a>
          <div className="phone">
            <img src="/assets/phone-icon.svg" alt="phone icon" />
            <p className="phone-number">&nbsp;+1 212 259 4305</p>
          </div>
          <ul className="list-unstyled list-inline social-media-icons">
            <li className="list-inline-item">
              <a href="https://twitter.com">
                <img src="/assets/twitterimg.svg" alt="twitter logo" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://linkedin.com">
                <img src="/assets/linkedinimg.svg" alt="linked in logo" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://facebook.com">
                <img src="/assets/facebookimg.svg" alt="facebook logo" />
              </a>
            </li>
          </ul>
        </Col>
        {version === 'home' && (
          <>
            <Col className="footer-col" md={2}>
              <ul className="list-unstyled first-list">
                <li>
                  <button
                    className="button-link"
                    onClick={_ =>
                      window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                      })
                    }
                  >
                    Home
                  </button>
                </li>
                <li>
                  <button
                    className="button-link"
                    onClick={_ => onScrollClick('howItWorks')}
                  >
                    How it works
                  </button>
                </li>
                <li>
                  <button
                    className="button-link"
                    onClick={_ => onScrollClick('howToPurchase')}
                  >
                    How to buy
                  </button>
                </li>
              </ul>
            </Col>
            <Col md={1} />
            <Col className="footer-col" md={2}>
              <ul className="list-unstyled second-list">
                <li>
                  <button
                    className="button-link"
                    onClick={_ => onScrollClick('howToSell')}
                  >
                    How to sell
                  </button>
                </li>
                <li>
                  <button
                    className="button-link"
                    onClick={_ => onScrollClick('legal')}
                  >
                    Legal
                  </button>
                </li>
              </ul>
            </Col>
          </>
        )}
        <Col
          className="footer-col company-info"
          md={version === 'home' ? 4 : 6}
        >
          <a className="brand" href="/">
            MINERAL MARKET
          </a>
          <p>
            Mineral Market is a new company dedicated to simplifying the market
            for cash flowing minerals and leasehold.
          </p>
        </Col>
      </Row>
      <div className="logo-container">
        <a
          className="copyright logo first-logo"
          href="http://cherokeeacq.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          © 2019 Cherokee Debt Acquisition, LLC
        </a>
        <a
          className="copyright logo"
          href="http://meeplabs.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Site Developed by MeepLabs
        </a>
      </div>
    </Container>
  )
}

export default Footer
