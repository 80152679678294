import React from 'react'

import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroupText,
  Row
} from 'reactstrap'

import './ContactUs.css'

class ContactUs extends React.Component {
  render() {
    const {
      firstName,
      lastName,
      email,
      company,
      subject,
      message,
      finalMessage
    } = this.props.contactUsFormState
    return (
      <Container fluid className="contact-us">
        <Row>
          <Col className="heading">
            <h3>Contact Us</h3>
            <hr />
            <p>
              We’re happy to help out with any questions you may have regarding
              a claim or transaction. Please fill in the information below and
              we’ll get back to you as soon as possible.
            </p>
          </Col>
        </Row>
        <Row className="form-row">
          <Col>
            <Form className="form" onSubmit={this.props.onContactUsSubmit}>
              <InputGroupText>
                <FormGroup>
                  <Input
                    type="text"
                    name="firstName"
                    value={firstName}
                    placeholder="First Name"
                    onChange={this.props.saveToState}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    name="lastName"
                    value={lastName}
                    placeholder="Last Name"
                    onChange={this.props.saveToState}
                  />
                </FormGroup>
              </InputGroupText>
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={this.props.saveToState}
                  autoComplete="email"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="company"
                  value={company}
                  placeholder="Company"
                  onChange={this.props.saveToState}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="subject"
                  value={subject}
                  placeholder="Subject"
                  onChange={this.props.saveToState}
                />
              </FormGroup>
              <FormGroup>
                <textarea
                  className="form-control"
                  rows="3"
                  placeholder="Message"
                  value={message}
                  name="message"
                  onChange={this.props.saveToState}
                />
              </FormGroup>
              <FormFeedback valid>{finalMessage}</FormFeedback>
              <Button className="default-button" type="submit">
                SEND
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    )
  }
}
export default ContactUs
