import React from 'react'

import {
  Button,
  Card,
  CardImg,
  CardHeader,
  CardText,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row
} from 'reactstrap'

import './HowDoesItWork.css'

class HowDoesItWork extends React.Component {
  render() {
    return (
      <Container className="how-does-it-work">
        <Row>
          <Col className="heading">
            <h3>How does Mineral Market work?</h3>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} className="first-card-col">
            <Card>
              <CardHeader>
                <CardImg src="/assets/oil-drilling.svg" alt="oil drilling" />
              </CardHeader>
              <CardBody>
                <CardTitle>MINERAL MARKET</CardTitle>
                <CardText>
                  Mineral Market is a new company dedicated to simplifying the
                  market for cash flowing minerals and leasehold. Our goal is to
                  bring together Buyers and Sellers of cash flowing minerals and
                  leasehold through a transparent and easy-to-user interface
                  that reduces transaction costs.
                  <br />
                  <br />
                  We curate all minerals and leasehold offered for sale on our
                  app. Only cash flowing minerals and leasehold with copies of
                  revenue checks will be offered. We compare acreage quantities
                  and royalty rates and N.R.I. to decimal interests on revenue
                  checks for consistency.
                </CardText>
              </CardBody>
            </Card>
            <div className="circle">
              <span>&#8594;</span>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <Card>
              <CardHeader>
                <CardImg
                  src="/assets/person-on-computer.svg"
                  alt="person on computer"
                />
              </CardHeader>
              <CardBody>
                <CardTitle>BUYERS</CardTitle>
                <CardText>
                  Buyers may select minerals and leasehold for purchase after
                  reviewing the minerals and leasehold offered. Offered prices
                  are firm and executable. Buyers are able to bid a price lower
                  than the offered price. However, there is no assurance that a
                  Seller will reduce their offered price in response to lower
                  bid. The first Buyer to agree to pay a price acceptable to a
                  Seller shall be designated as the winning Buyer. Once cleared
                  funds are received by the Seller and Mineral Market, Mineral
                  Market will name the winning Buyer as Grantee or Assignee, and
                  overnight the original Mineral Deed or Assignment to the
                  winning Buyer.
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="button-container">
            <Button
              className="default-button first-button"
              href="https://play.google.com/store/apps/details?id=com.cherokeeacq.mineralmarket"
              target="_blank"
              rel="noopener noreferrer"
            >
              ANDROID APP
            </Button>
            <Button
              className="default-button second-button"
              href="https://itunes.apple.com/us/app/mineral-market/id1315532516?mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              IOS APP
            </Button>
          </Col>
        </Row>
      </Container>
    )
  }
}
export default HowDoesItWork
