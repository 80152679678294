import React from 'react'

import NavbarContainer from '../../Components/Navbar/NavbarContainer'
import Hero from '../../Components/Home/Hero/Hero'

import './Home.css'
import HowDoesItWork from '../../Components/Home/HowDoesItWork/HowDoesItWork'
import HowToPurchase from '../../Components/Home/HowToPurchase/HowToPurchase'
import HowToSell from '../../Components/Home/HowToSell/HowToSell'
import Legal from '../../Components/Home/Legal/Legal'
import ContactUs from '../../Components/Home/ContactUs/ContactUs'
import Footer from '../../Components/Footer/Footer'

function Home({
  history,
  onScrollClick,
  seeLeasehold,
  seeMinerals,
  downloadApp,
  howItWorks,
  howToPurchase,
  howToSell,
  downloadMineralDeedDocument,
  downloadPSAAgreement,
  legal,
  onContactUsSubmit,
  saveToState,
  contactUsFormState,
  contactUs,
  user,
  checkForUser
}) {
  return (
    <>
      <div className="home-top">
        <NavbarContainer
          version="home"
          history={history}
          onScrollClick={onScrollClick}
          user={user}
          checkForUser={checkForUser}
        />
        <Hero seeLeasehold={seeLeasehold} seeMinerals={seeMinerals} />
      </div>
      <HowDoesItWork downloadApp={downloadApp} ref={howItWorks} />
      <HowToPurchase ref={howToPurchase} />
      <HowToSell ref={howToSell} />
      <Legal
        downloadMineralDeedDocument={downloadMineralDeedDocument}
        downloadPSAAgreement={downloadPSAAgreement}
        ref={legal}
      />
      <ContactUs
        onContactUsSubmit={onContactUsSubmit}
        saveToState={saveToState}
        contactUsFormState={contactUsFormState}
        ref={contactUs}
      />
      <Footer onScrollClick={onScrollClick} version="home" />
    </>
  )
}
export default Home
