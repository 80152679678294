import React from 'react'

import { Col, Container, Row } from 'reactstrap'

import './HowToSell.css'

class HowToSell extends React.Component {
  render() {
    return (
      <Container fluid className="how-to-sell">
        <Row>
          <Col className="heading">
            <h3>How to Sell?</h3>
          </Col>
        </Row>
        <Row>
          <Col className="img-col">
            <img
              src="/assets/hand-using-tablet.svg"
              alt="hand using tablet design"
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-col">
            <h5>SELLERS</h5>
            <p>
              Sellers send us an original notarized copy of the outgoing Mineral
              Deed or Assignment together with copies of revenue checks and
              other documents.
            </p>
            <p>
              Sellers also sign a Seller’s Agreement (see Legal section below)
              to offer minerals or leasehold exclusively through Mineral Market
              for a period of time. Sellers do not pay any fees or commissions.
              All fees are paid by the Buyer.
            </p>
            <p>
              Affiliates of Mineral Market may offer minerals or leasehold for
              sale on the app.
            </p>
          </Col>
        </Row>
      </Container>
    )
  }
}
export default HowToSell
