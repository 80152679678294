import React from 'react'
import Navbar from './Navbar'
import { withRouter } from 'react-router-dom'
import logout from '../../ApiCalls/Logout'

class NavbarContainer extends React.Component {
  state = {
    isOpen: false,
    signInOpen: false,
    signUpOpen: false
  }
  toggle = () =>
    this.setState({
      isOpen: !this.state.isOpen
    })
  modalToggle = modalType =>
    this.setState({
      [modalType]: !this.state[modalType]
    })
  logout = async () => {
    const logoutAttempt = await logout()
    if (logoutAttempt.success === true) {
      this.props.checkForUser(true)
    }
  }
  signUp = () => this.props.push('/sign-up')
  linkToHome = () => this.props.history.push('/')
  linkToMinerals = () => this.props.history.push('/assets')
  linkToLeaseholds = () => this.props.history.push('/leaseholds')
  redirectToHome = section =>
    this.props.history.push({
      pathname: '/',
      state: {
        section
      }
    })
  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.signUpOpen !== this.state.signUpOpen ||
      nextState.signInOpen !== this.state.signInOpen ||
      nextState.isOpen !== this.state.isOpen ||
      nextProps.user.name !== this.props.user.name
    ) {
      return true
    } else {
      return false
    }
  }
  render() {
    return (
      <Navbar
        checkForUser={this.props.checkForUser}
        isOpen={this.state.isOpen}
        toggle={this.toggle}
        signInOpen={this.state.signInOpen}
        signUpOpen={this.state.signUpOpen}
        modalToggle={this.modalToggle}
        logout={this.logout}
        signUp={this.signUp}
        state={this.state}
        linkToHome={this.linkToHome}
        linkToMinerals={this.linkToMinerals}
        linkToLeaseholds={this.linkToLeaseholds}
        redirectToHome={this.redirectToHome}
        onScrollClick={this.props.onScrollClick}
        version={this.props.version}
        user={this.props.user}
      />
    )
  }
}

export default withRouter(NavbarContainer)
