export default () =>
  fetch('/api/users/logged-in', {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    referrer: 'no-referrer'
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      console.log('err', err)
      return {
        err,
        success: false
      }
    })
