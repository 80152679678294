import React from 'react'
import { Switch, Route } from 'react-router-dom'

import HomeContainer from '../Pages/Home/HomeContainer'
import MineralLeaseholdContainer from '../Pages/MineralLeasehold/MineralLeaseholdContainer'
import AssetContainer from '../Pages/Asset/AssetContainer'
import FourOFour from '../Pages/404/404'

function Routes({
  history,
  home,
  howItWorks,
  howToPurchase,
  howToSell,
  legal,
  contactUs,
  onScrollClick,
  user,
  checkForUser
}) {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={_ => {
          return (
            <HomeContainer
              history={history}
              home={home}
              howItWorks={howItWorks}
              howToPurchase={howToPurchase}
              howToSell={howToSell}
              legal={legal}
              contactUs={contactUs}
              onScrollClick={onScrollClick}
              user={user}
              checkForUser={checkForUser}
            />
          )
        }}
      />
      <Route
        exact
        path="/assets"
        render={_ => (
          <MineralLeaseholdContainer
            history={history}
            user={user}
            checkForUser={checkForUser}
            onScrollClick={onScrollClick}
            title="Minerals"
          />
        )}
      />
      <Route
        exact
        path="/leaseholds"
        render={_ => (
          <MineralLeaseholdContainer
            history={history}
            user={user}
            checkForUser={checkForUser}
            onScrollClick={onScrollClick}
            title="Leasehold"
          />
        )}
      />
      <Route
        exact
        path="/asset/:id"
        render={props => (
          <AssetContainer
            {...props}
            user={user}
            checkForUser={checkForUser}
            onScrollClick={onScrollClick}
          />
        )}
      />
      <Route
        exact
        path="/leasehold/:id"
        render={props => (
          <MineralLeaseholdContainer
            {...props}
            user={user}
            checkForUser={checkForUser}
            onScrollClick={onScrollClick}
          />
        )}
      />
      <Route render={props => <FourOFour {...props} user={user} />} />
    </Switch>
  )
}

export default Routes
