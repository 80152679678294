import React from 'react'

import Footer from '../../Components/Footer/Footer'
import NavbarContainer from '../../Components/Navbar/NavbarContainer'

import './404.css'

export default function fourOFour(props) {
  const { user } = props
  return (
    <div className="fourOFour">
      <NavbarContainer
        version="home"
        user={user}
        checkForUser={props.checkForUser}
      />
      <div className="surround">
        <div className="jumbotron mb-0 text-center">
          <h1 className="display-4">404</h1>
          <p className="lead">
            Sorry but the page you are looking for was not found.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}
