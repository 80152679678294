export default async userData => {
  const checkForUser = await fetch(
    `https://mineralmarket.herokuapp.com/pusers?username=${userData.email}`
  )
    .then(response => response.json())
    .then(data => {
      userData.username = data.username
      userData.id = data.id
      return data
    })
    .catch(err => {
      return err
    })
  if (checkForUser.length === 0) {
    return {
      err: checkForUser,
      success: false
    }
  } else {
    userData.id = checkForUser[0].id
  }
  const signInInit = await fetch(
    'https://mineralmarket.herokuapp.com/pusers/' + userData.id
  )
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    })
  if (signInInit.id) {
    signInInit.name = userData.name
    signInInit.email = userData.email
    return fetch('/api/auth/login', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(signInInit)
    })
      .then(response => response.json())
      .then(data => {
        return data
      })
      .catch(err => {
        return {
          err,
          success: false
        }
      })
  } else {
    // check later what happens when trying to access db incorrectly
    return {
      success: false,
      err: signInInit
    }
  }
}
