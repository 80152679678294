import React from 'react'

import { Input, InputGroup, InputGroupAddon } from 'reactstrap'

function Text({ autoFocus, autoComplete, name, placeholder, required, saveToState, value  }) {
  return (
    <InputGroup>
      <InputGroupAddon addonType="prepend">
        <img src="/assets/flag-minmarket.svg" alt="small flag" />
      </InputGroupAddon>
      <Input
        placeholder={placeholder}
        type="text"
        name={name}
        autoComplete={autoComplete}
        autoCapitalize="true"
        autoFocus={autoFocus}
        required={required}
        onChange={saveToState}
        value={value}
      />
    </InputGroup>
  )
}

export default Text
