export default async initData => {
  const checkIfPreviousUser = await fetch(
    `https://mineralmarket.herokuapp.com/pusers?username=${initData.username}`
  )
    .then(response => response.json())
    .then(data => {
      return data
    })
  if (checkIfPreviousUser.length === 0) {
    return fetch('https://mineralmarket.herokuapp.com/pusers', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(initData)
    })
      .then(response => response.json())
      .then(data => {
        data.name = initData.fullname
        data.email = initData.username
        return {
          success: true,
          user: data,
          username: data.username,
          id: data.id
        }
      })
      .catch(err => {
        return {
          err,
          success: false
        }
      })
  } else {
    return {
      success: false,
      stack: '',
      err: 'User already exists'
    }
  }
  // if (signUpInit.userData && signUpInit.userData.username) {
  //   return fetch('/api/users', {
  //     method: 'POST',
  //     mode: 'cors',
  //     cache: 'no-cache',
  //     credentials: 'same-origin',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     redirect: 'follow',
  //     referrer: 'no-referrer',
  //     body: JSON.stringify(secondData)
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       if (typeof data.__v !== 'number') {
  //         return {
  //           success: false,
  //           message: data.message,
  //           stack: data.stack
  //         }
  //       }
  //       return {
  //         success: true,
  //         userData: data,
  //         username: signUpInit.userData.username,
  //         id: signUpInit.userData.id
  //       }
  //     })
  //     .catch(err => {
  //       return {
  //         err,
  //         success: false
  //       }
  //     })
  // } else {
  //   return {
  //     success: false,
  //     err: signUpInit
  //   }
  // }
}
