import React from 'react'

import { Col, Container, Row } from 'reactstrap'

import './HowToPurchase.css'

class HowToPurchase extends React.Component {
  render() {
    return (
      <Container fluid className="how-to-purchase">
        <Row>
          <Col className="heading">
            <h3>How to Buy?</h3>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col className="margin-top" sm={12} md={4}>
            <h4>DILIGENCE</h4>
            <p>
              Buyers need to sign a Buyer’s Agreement (see Legal section below)
              in order to receive a full diligence package. We have a full data
              package provided to us by the owner including, revenue checks and
              the vesting deed or lease the owner used to initially purchase the
              minerals or leasehold. We also have an original copy of the
              outgoing deed or leasehold assignment that the owner will use to
              convey the minerals or leasehold to you.
            </p>
          </Col>
          <Col className="img-col" sm={12} md={4}>
            <img src="/assets/person-on-computer.svg" alt="guy on computer" />
          </Col>
          <Col className="margin-top" sm={12} md={4}>
            <h4>CLOSING</h4>
            <p>
              At closing, you make payment directly to the Seller and pay us a
              3% transaction fee. Upon receipt of funds, we send you the
              original Mineral Deed or Assignment.
            </p>
          </Col>
        </Row>
      </Container>
    )
  }
}
export default HowToPurchase
